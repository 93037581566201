.db-blog__teaser,
.db-blog__post {
	// outline: 1px solid red;
	clear: both;

	margin-bottom: $vertical-space-normal;
}

.db-blog__teaser__item {
	margin-bottom: 10vw;

	@include breakpoint(mid) {
		margin-bottom: $vertical-space-normal;
	}
}

.db-blog__teaser__title {
	padding-bottom: 0;
	margin-bottom: 36px;

	@include breakpoint(mid) {
		margin-bottom: 3vw;
	}
}

.db-blog__teaser__text {
	margin-bottom: 12px;
	@include breakpoint(max) {
		margin-bottom: $vertical-space-tiny;
	}
}

.db-blog__teaser__content {
	padding-bottom: $vertical-space-small;

	p:last-child {
		margin-bottom: 0;
	}
}

.db-blog__mediawrapper {
	margin-bottom: 24px;

	@include breakpoint(mid) {
		margin-bottom: $vertical-space-small;
	}

	@include breakpoint(max) {
		margin-bottom: $vertical-space-small;
	}
}

.db-blog__readmore {
	@include font-family(regular);
	background: transparent;
	text-decoration: none;
	border: none;
	border-bottom: 3px solid transparent;
	transition: $transition;
	cursor: pointer;

	&--hidden {
		opacity: 0;
		visibility: hidden;
	}
}

//
// Initially hidden content
//
.db-blog__teaser__collapsed-content {
	display: none;

	&--open {
		display: block;
	}

	& > *:last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&.focus-visible {
		outline: none;
	}
}

.db-blog__post__date {
	@include font-family(headlight);

	@include breakpoint(min) {
		font-size: 18px;
		line-height: 36px;
		margin-bottom: $vertical-space-small;
	}

	@include breakpoint(mid) {
		font-size: calc(20px + (24 - 20) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		line-height: calc(40px + (48 - 40) * ((100vw - #{$textmidbreakpoint}px) / (1600 - #{$textmidbreakpoint})));
		margin-bottom: $vertical-space-small;
	}

	@include breakpoint(max) {
		font-size: 1.5vw;
		line-height: 3vw;
		margin-bottom: $vertical-space-tiny;
	}
}

//
// TOggle to show hidden content
//
.db-blog__button-wrapper {
	margin-bottom: 0;
}
