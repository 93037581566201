.db-blog {
	&__image-slider {
		width: 100%;
		margin-top: 5vh;
		margin-bottom: 5vh;
	}

	&__slider-navigation {
		grid-column: 1/3;
		grid-row: 4;
		margin: 24px 0;
	}

	&__slider-pagination {
		display: flex;
		justify-content: center;
	}
}
